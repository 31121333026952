.teamLabel {
  position: relative;
  display: flex;
  align-items: center;

  .teamLabel__icon {
    display: inline-block;
    background: #c695ff;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin-right: 12px;
  }
}