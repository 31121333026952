$click-span: #0084FFB9;
$label-width: 127px;
$padding: 15px;
$fontsize: 14px;

@mixin click-span {
  color: $click-span;
  margin-right: 30px;
  cursor: pointer;
  
  &:last-child {
    margin-right: 0;
  }
}

@mixin list {
  padding-left: 37px;
}

@mixin problem-part {
  font-size: $fontsize;
  margin: 10px 0;
  border: 1px solid transparent;
}

@mixin answer-prompt {
  color: #c0c0c0;
  font-weight: normal;
}

@mixin default-text {
  font-size: 14px;
}

@mixin show-default-input-icon {
  min-width: 10px;
  background-image: url('assets/defaultInputIcon.png');
  background-position: 0 -1px;
  background-repeat: no-repeat;
}