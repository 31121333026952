@import "src/common";

#paperType {
  
  .line {
    border-bottom: 1px dashed #ccc;
  }
  
  .paperTypeTitle {
    display: flex;
    justify-content: space-between;
    
    span {
      display: flex;
      flex-direction: column;
      align-self: end;
    }
    
    .buttons {
      margin-bottom: 20px;
    }
  }
  
  .labelBox {
    display: flex;
    cursor: default;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 15px;
    
    .labelButton {
      display: flex;
      width: $label-width;
      padding: 5px 15px;
      border: 1px solid rosybrown;
      border-radius: 5px;
      margin: 15px 15px 0 0;
      //margin-left: 20px;
      //text-align: center;
      //justify-content: center;
      
      
      .name {
        width: calc($label-width - 2 * $padding);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .labelButton:last-child {
        margin-right: 0;
      }
    }
    
    
    
    i {
      width: 127px;
      margin-right: 15px;
    }
  }
  
}