.group {
  .group__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .group__input {
    width: 200px;
  }

  .group__action {
    display: flex;

    .group__btn {
      white-space: nowrap;
      cursor: pointer;
      color: #2e30fd;

      &:after {
        margin-left: 4px;
        margin-right: 4px;
        content: '|';
        color: black;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}