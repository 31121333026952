@import "src/common";

#publicProblemCenter {
  
  .subjects {
    display: flex;
    
    & > .category {
      @include click-span;
    }
  }
  
  .subjectName {
    // @include click-span;
  }
}