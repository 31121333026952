#App {
  height: 100%;
  
  #login {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f7f9fc;

    .header {
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0 86px;
      height: var(--header-height);
      box-shadow: 0px 1px 2px 0px rgba(0, 21, 41, 0.12);


      & > img {
        display: inline-block;
        //margin: 7% 0;
        height: calc(0.66 * var(--header-height));
        width: auto;
      }
    }
    
    
    .login-form {
      min-height: 346px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      width: 420px;
      align-self: center;
      border-radius: 10px;
      overflow: hidden;
      padding-top: 20px;

      .login-title {
        font-weight: 700;
        font-style: normal;
        font-size: 28px;
        text-align: center;
        color: #333;
      }

      .login-content {
        width: 330px;
        margin: 0 auto;
        padding: 38px 0 30px;
      }

      .forgetPassword {
        text-align: right;
        color: #999;
        margin: 0;
      }
    }
  }
  
  
  .avatar {
    width: 25px;
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .ant-menu-item-selected {
    background: rgba(0, 0, 0, 0.04);
  }

  
  // 右上角个人中心
  .avatarDropdown {

    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
    }

    .ant-dropdown {
      min-width: auto!important;
    }

    .personalCenter {
      
      span {
        text-align: center;
      }
    }
  }
}

