html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
  
  .yl-prefix-top-nav-header-main-left {
    margin-right: 50px;
    
    h1 {
      font-size: 20px;
    }
  }
  
  .yl-prefix-top-nav-header-menu ul li {
    padding: 0;
  }
  
  .ant-pro-global-header-header-actions-avatar {
    margin-right: 20px;
  }
  
  header .ant-menu-title-content > div {
    padding: 0 16px;
  }
  
  .ant-pro-page-container-warp-page-header {
    padding-inline: 5%;
  }
  .ant-pro-page-container-children-container {
    padding-inline: 5%;
  }
}

.yl-table-actions {
  display: flex;
}
.yl-table-btn {
  cursor: pointer;
  color: #2e30fd;
  &.yl-table-btn--black {
    color: #000;
    cursor:default;
  }
  &.yl-table-btn--left-auto {
    margin-left: auto;
  }
  &.yl-table-btn--no-line {
    &:before {
      content: "";
    }
  }
  &:before{
    margin-left: 4px;
    margin-right: 4px;
    content: '|';
    color: black;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
}

$--color-border: #d2cece;
.yl-custom-table {
  .yl-custom-table__body {
    display: flex;
  }
  .yl-custom-table__name {
    border-top: 1px solid $--color-border;
    border-left: 1px solid $--color-border; 
    border-bottom: 1px solid $--color-border; 
    display: flex;
    align-items: center;
    padding: 8px;
  }
  .yl-custom-table__content {
    border-top: 1px solid $--color-border;
    border-left: 1px solid $--color-border; 
    flex:1;
  }
  .yl-custom-table__row {
    display: flex;
  }
  .yl-custom-table__item {
    border-right: 1px solid $--color-border;
    border-bottom: 1px solid $--color-border;
    flex: 1;
    padding: 4px;
    text-align: center;
    white-space: nowrap;
    // &.
  }
  .yl-custom-table__key {
    border-right: 1px solid $--color-border;
    border-bottom: 1px solid $--color-border;
    width: 200px;
    text-align: right;
    padding: 4px;
  }
  .yl-custom-table__value {
    border-right: 1px solid $--color-border;
    border-bottom: 1px solid $--color-border;
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 4px;
  }
  .yl-custom-table__line {
    margin: 0 4px;
  }
  .yl-custom-table__btn {
    cursor: pointer;
    margin-right: 8px;
    &.yl-custom-table__btn--blue {
      color: blue;
    }
    &.yl-custom-table__btn--red {
      color: red;
    }
  }
}
.yl-break-all {
  word-break: break-all;
}